/******************************************************************
  Template Name: Male Fashion
  Description: Male Fashion - ecommerce teplate
  Author: Colorib
  Author URI: https://www.colorib.com/
  Version: 1.0
  Created: Colorib 
******************************************************************/

/*------------------------------------------------------------------
[Table of contents]

1.  Template default CSS
	1.1	Variables
	1.2	Mixins
	1.3	Flexbox
	1.4	Reset
2.  Helper Css
3.  Header Section
4.  Hero Section
5.  Banner Section
6.  Product Section
7.  Intagram Section
8.  Latest Section
9.  Contact
10.  Footer Style
-------------------------------------------------------------------*/

/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/

html,
body {
  height: 100%;
  /* font-family: "Nunito Sans"; */
  -webkit-font-smoothing: antialiased;
}
.parentDiv h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #111111;
  font-weight: 400;
  /* font-family: "Nunito Sans"; */
}
.parentDiv h1 {
  font-size: 70px;
}
.parentDiv h2 {
  font-size: 36px;
}
.parentDiv h3 {
  font-size: 30px;
}
.parentDiv h4 {
  font-size: 24px;
}
.parentDiv h5 {
  font-size: 18px;
}
.parentDiv h6 {
  font-size: 16px;
}
.parentDiv p {
  font-size: 15px;
  /* font-family: "Nunito Sans"; */
  color: #3d3d3d;
  font-weight: 400;
  line-height: 25px;
  margin: 0 0 15px 0;
}
.parentDiv img {
  max-width: 100%;
}
.parentDiv input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}
.parentDiv a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: #ffffff;
}
.parentDiv ul,
ol {
  padding: 0;
  margin: 0;
}
.parentDiv

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
  margin-bottom: 45px;
  text-align: center;
}
.parentDiv .section-title span {
  color: #e53637;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
  display: block;
}
.parentDiv .section-title h2 {
  color: #111111;
  font-weight: 700;
  line-height: 46px;
}
.parentDiv .set-bg {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}
.parentDiv .spad {
  padding-top: 100px;
  padding-bottom: 100px;
}
.parentDiv .text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #fff;
}
.parentDiv

/* buttons */

.primary-btn {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 14px 30px;
  color: #ffffff;
  background: #000000;
  letter-spacing: 4px;
}
.parentDiv .site-btn {
  font-size: 14px;
  color: #ffffff;
  background: #111111;
  font-weight: 700;
  border: none;
  text-transform: uppercase;
  display: inline-block;
  padding: 14px 30px;
}
.parentDiv

/* Preloder */

#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000;
}
.parentDiv .loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
}
:local(table) {
  border-collapse: collapse;
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}
.parentDiv .spacial-controls {
  position: fixed;
  width: 111px;
  height: 91px;
  top: 0;
  right: 0;
  z-index: 999;
}
.parentDiv .spacial-controls .search-switch {
  display: block;
  height: 100%;
  padding-top: 30px;
  background: #323232;
  text-align: center;
  cursor: pointer;
}
.parentDiv .search-model {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
  z-index: 99999;
}
.parentDiv .search-model-form {
  padding: 0 15px;
}
.parentDiv .search-model-form input {
  width: 500px;
  font-size: 40px;
  border: none;
  border-bottom: 2px solid #333;
  background: 0 0;
  color: #999;
}
.parentDiv .search-close-switch {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #333;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 28px;
  line-height: 28px;
  top: 30px;
  cursor: pointer;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.parentDiv

/*---------------------
  Header
-----------------------*/

.header {
  background: #ffffff;
}
.parentDiv .header__top {
  background: #111111;
  padding: 10px 0;
}
.parentDiv .header__top__left p {
  color: #ffffff;
  margin-bottom: 0;
}
.parentDiv .header__top__right {
  text-align: right;
}
.parentDiv .header__top__links {
  display: inline-block;
  margin-right: 25px;
}
.parentDiv .header__top__links a {
  color: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 28px;
  display: inline-block;
}
.parentDiv .header__top__links a:last-child {
  margin-right: 0;
}
.parentDiv .header__top__hover {
  display: inline-block;
  position: relative;
}
.parentDiv .header__top__hover:hover ul {
  top: 24px;
  opacity: 1;
  visibility: visible;
}
.parentDiv .header__top__hover span {
  color: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  cursor: pointer;
}
.parentDiv .header__top__hover span i {
  font-size: 20px;
  position: relative;
  top: 3px;
  right: 2px;
}
.parentDiv .header__top__hover ul {
  background: #ffffff;
  display: inline-block;
  padding: 2px 0;
  position: absolute;
  left: 0;
  top: 44px;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .header__top__hover ul li {
  list-style: none;
  font-size: 13px;
  color: #111111;
  padding: 2px 15px;
  cursor: pointer;
}
.parentDiv .header__logo {
  padding: 30px 0;
}
.parentDiv .header__logo a {
  display: inline-block;
}
.parentDiv .header__menu {
  text-align: center;
  padding: 26px 0 25px;
}
.parentDiv .header__menu ul li {
  list-style: none;
  display: inline-block;
  margin-right: 45px;
  position: relative;
}
.parentDiv .header__menu ul li.active a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.parentDiv .header__menu ul li:hover a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.parentDiv .header__menu ul li:hover .dropdown {
  top: 32px;
  opacity: 1;
  visibility: visible;
}
.parentDiv .header__menu ul li:last-child {
  margin-right: 0;
}
.parentDiv .header__menu ul li .dropdown {
  position: absolute;
  left: 0;
  top: 56px;
  width: 150px;
  background: #111111;
  text-align: left;
  padding: 5px 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .header__menu ul li .dropdown li {
  display: block;
  margin-right: 0;
}
.parentDiv .header__menu ul li .dropdown li a {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  padding: 5px 20px;
  text-transform: capitalize;
}
.parentDiv .header__menu ul li .dropdown li a:after {
  display: none;
}
.parentDiv .header__menu ul li a {
  font-size: 18px;
  color: #111111;
  display: block;
  font-weight: 600;
  position: relative;
  padding: 3px 0;
}
.parentDiv .header__menu ul li a:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #e53637;
  content: "";
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.parentDiv .header__nav__option {
  text-align: right;
  padding: 30px 0;
}
.parentDiv .header__nav__option a {
  display: inline-block;
  margin-right: 26px;
  position: relative;
}
.parentDiv .header__nav__option a span {
  color: #0d0d0d;
  font-size: 11px;
  position: absolute;
  left: 5px;
  top: 8px;
}
.parentDiv .header__nav__option a:last-child {
  margin-right: 0;
}
.parentDiv .header__nav__option .price {
  font-size: 15px;
  color: #111111;
  font-weight: 700;
  display: inline-block;
  margin-left: -20px;
  position: relative;
  top: 3px;
}
.parentDiv .offcanvas-menu-wrapper {
  display: none;
}
.parentDiv .canvas__open {
  display: none;
}
.parentDiv

/*---------------------
  Hero
-----------------------*/

.hero__slider.owl-carousel .owl-item.active .hero__text h6 {
  top: 0;
  opacity: 1;
}
.parentDiv .hero__slider.owl-carousel .owl-item.active .hero__text h2 {
  top: 0;
  opacity: 1;
}
.parentDiv .hero__slider.owl-carousel .owl-item.active .hero__text p {
  top: 0;
  opacity: 1;
}
.parentDiv
  .hero__slider.owl-carousel
  .owl-item.active
  .hero__text
  .primary-btn {
  top: 0;
  opacity: 1;
}
.parentDiv .hero__slider.owl-carousel .owl-nav button {
  font-size: 36px;
  color: #333333;
  position: absolute;
  left: 75px;
  top: 50%;
  margin-top: -18px;
  line-height: 29px;
}
.parentDiv .hero__slider.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 75px;
}
.parentDiv .hero__items {
  height: 800px;
  padding-top: 230px;
}
.parentDiv .hero__text h6 {
  color: #e53637;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 28px;
  position: relative;
  top: 100px;
  opacity: 0;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .hero__text h2 {
  color: #111111;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  margin-bottom: 30px;
  position: relative;
  top: 100px;
  opacity: 0;
  -webkit-transition: all, 0.6s;
  -o-transition: all, 0.6s;
  transition: all, 0.6s;
}
.parentDiv .hero__text p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 35px;
  position: relative;
  top: 100px;
  opacity: 0;
  -webkit-transition: all, 0.9s;
  -o-transition: all, 0.9s;
  transition: all, 0.9s;
}
.parentDiv .hero__text .primary-btn {
  position: relative;
  top: 100px;
  opacity: 0;
  -webkit-transition: all, 1.1s;
  -o-transition: all, 1.1s;
  transition: all, 1.1s;
}
.parentDiv .hero__text .primary-btn span {
  font-size: 20px;
  position: relative;
  top: 4px;
  font-weight: 700;
}
.parentDiv .hero__social {
  margin-top: 190px;
}
.parentDiv .hero__social a {
  font-size: 16px;
  color: #3d3d3d;
  display: inline-block;
  margin-right: 32px;
}
.parentDiv .hero__social a:last-child {
  margin-right: 0;
}
.parentDiv

/*---------------------
  Banner
-----------------------*/

.blog {
  padding-bottom: 55px;
}
.parentDiv .banner__item {
  position: relative;
  overflow: hidden;
}
.parentDiv .banner__item:hover .banner__item__text a:after {
  width: 40px;
  background: #e53637;
}
.parentDiv .banner__item.banner__item--middle {
  margin-top: -75px;
}
.parentDiv .banner__item.banner__item--middle .banner__item__pic {
  float: none;
}
.parentDiv .banner__item.banner__item--middle .banner__item__text {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;
  padding-top: 22px;
}
.parentDiv .banner__item.banner__item--last {
  margin-top: 100px;
}
.parentDiv .banner__item__pic {
  float: right;
}
.parentDiv .banner__item__text {
  max-width: 300px;
  position: absolute;
  left: 0;
  top: 140px;
}
.parentDiv .banner__item__text h2 {
  color: #111111;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 10px;
}
.parentDiv .banner__item__text a {
  display: inline-block;
  color: #111111;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding: 3px 0;
  position: relative;
}
.parentDiv .banner__item__text a:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #111111;
  content: "";
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv

/*---------------------
  Categories
-----------------------*/

.categories {
  background: #f3f2ee;
  overflow: hidden;
  padding-top: 150px;
  padding-bottom: 125px;
}
.parentDiv .categories__text {
  padding-top: 40px;
  position: relative;
  z-index: 1;
}
.parentDiv .categories__text:before {
  position: absolute;
  left: -485px;
  top: 0;
  height: 300px;
  width: 600px;
  background: #ffffff;
  z-index: -1;
  content: "";
}
.parentDiv .categories__text h2 {
  color: #b7b7b7;
  line-height: 72px;
  font-size: 34px;
}
.parentDiv .categories__text h2 span {
  font-weight: 700;
  color: #111111;
}
.parentDiv .categories__hot__deal {
  position: relative;
  z-index: 5;
}
.parentDiv .categories__hot__deal img {
  min-width: 100%;
}
.parentDiv .hot__deal__sticker {
  height: 100px;
  width: 100px;
  background: #111111;
  border-radius: 50%;
  padding-top: 22px;
  text-align: center;
  position: absolute;
  right: 0;
  top: -36px;
}
.parentDiv .hot__deal__sticker span {
  display: block;
  font-size: 15px;
  color: #ffffff;
  margin-bottom: 4px;
}
.parentDiv .hot__deal__sticker h5 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}
.parentDiv .categories__deal__countdown span {
  color: #e53637;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 15px;
  display: block;
}
.parentDiv .categories__deal__countdown h2 {
  color: #111111;
  font-weight: 700;
  line-height: 46px;
  margin-bottom: 25px;
}
.parentDiv .categories__deal__countdown .categories__deal__countdown__timer {
  margin-bottom: 20px;
  overflow: hidden;
  margin-left: -30px;
}
.parentDiv
  .categories__deal__countdown
  .categories__deal__countdown__timer
  .cd-item {
  width: 25%;
  float: left;
  margin-bottom: 25px;
  text-align: center;
  position: relative;
}
.parentDiv
  .categories__deal__countdown
  .categories__deal__countdown__timer
  .cd-item:after {
  position: absolute;
  right: 0;
  top: 7px;
  content: ":";
  font-size: 24px;
  font-weight: 700;
  color: #3d3d3d;
}
.parentDiv
  .categories__deal__countdown
  .categories__deal__countdown__timer
  .cd-item:last-child:after {
  display: none;
}
.parentDiv
  .categories__deal__countdown
  .categories__deal__countdown__timer
  .cd-item
  span {
  color: #111111;
  font-weight: 700;
  display: block;
  font-size: 36px;
}
.parentDiv
  .categories__deal__countdown
  .categories__deal__countdown__timer
  .cd-item
  p {
  margin-bottom: 0;
}
.parentDiv

/*---------------------
  Instagram
-----------------------*/

.instagram {
  padding-bottom: 0;
}
.parentDiv .instagram__pic__item {
  width: 33.33%;
  float: left;
  height: 261px;
  background-position: center center;
}
.parentDiv .instagram__text {
  padding-top: 130px;
}
.parentDiv .instagram__text h2 {
  color: #111111;
  font-weight: 700;
  margin-bottom: 30px;
}
.parentDiv .instagram__text p {
  margin-bottom: 65px;
}
.parentDiv .instagram__text h3 {
  color: #e53637;
  font-weight: 700;
}
.parentDiv

/*---------------------
  Product
-----------------------*/

.product {
  padding-top: 0;
  padding-bottom: 60px;
}
.parentDiv .filter__controls {
  text-align: center;
  margin-bottom: 45px;
}
.parentDiv .filter__controls li {
  color: #b7b7b7;
  font-size: 24px;
  font-weight: 700;
  list-style: none;
  display: inline-block;
  margin-right: 88px;
  cursor: pointer;
}
.parentDiv .filter__controls li:last-child {
  margin-right: 0;
}
.parentDiv .filter__controls li.active {
  color: #111111;
}
.parentDiv .product__item {
  overflow: hidden;
  margin-bottom: 40px;
}
.parentDiv .product__item.sale .product__item__pic .label {
  color: #ffffff;
  background: #111111;
}
.parentDiv .product__item.sale .product__item__text .rating i {
  color: #f7941d;
}
.parentDiv
  .product__item.sale
  .product__item__text
  .rating
  i:nth-last-child(1) {
  color: #b7b7b7;
}
.parentDiv .product__item:hover .product__item__pic .product__hover {
  right: 20px;
  opacity: 1;
}
.parentDiv .product__item:hover .product__item__text a {
  top: 22px;
  opacity: 1;
  visibility: visible;
}
.parentDiv .product__item:hover .product__item__text h6 {
  /* opacity: 0; */
}
.parentDiv .product__item:hover .product__item__text .product__color__select {
  opacity: 1;
}
.parentDiv .product__item__pic {
  height: 260px;
  position: relative;
  background-position: center center;
}
.parentDiv .product__item__pic .label {
  color: #111111;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 15px 2px;
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 20px;
}
.parentDiv .product__item__pic .product__hover {
  position: absolute;
  right: -200px;
  top: 20px;
  -webkit-transition: all, 0.8s;
  -o-transition: all, 0.8s;
  transition: all, 0.8s;
}
.parentDiv .product__item__pic .product__hover li {
  list-style: none;
  margin-bottom: 10px;
  position: relative;
}
.parentDiv .product__item__pic .product__hover li:hover span {
  opacity: 1;
  visibility: visible;
}
.parentDiv .product__item__pic .product__hover li span {
  color: #ffffff;
  background: #111111;
  display: inline-block;
  padding: 4px 10px;
  font-size: 12px;
  position: absolute;
  left: -78px;
  top: 5px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .product__item__pic .product__hover li span:after {
  position: absolute;
  right: -2px;
  top: 5px;
  height: 15px;
  width: 15px;
  background: #111111;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}
.parentDiv .product__item__pic .product__hover li img {
  background: #ffffff;
  padding: 10px;
  display: inline-block;
}
.parentDiv .product__item__text {
  padding-top: 25px;
  position: relative;
}
.parentDiv .product__item__text a {
  font-size: 15px;
  color: #e53637;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .product__item__text h6 {
  color: #111111;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .product__item__text .rating {
  margin-bottom: 6px;
}
.parentDiv .product__item__text .rating i {
  font-size: 14px;
  color: #b7b7b7;
  margin-right: -5px;
}
.parentDiv .product__item__text h5 {
  color: #0d0d0d;
  font-weight: 700;
}
.parentDiv .product__item__text .product__color__select {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}
.parentDiv .product__item__text .product__color__select label {
  display: inline-block;
  height: 12px;
  width: 12px;
  background: #5e64d1;
  border-radius: 50%;
  margin-bottom: 0;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
}
.parentDiv .product__item__text .product__color__select label.black {
  background: #404a47;
}
.parentDiv .product__item__text .product__color__select label.grey {
  background: #d5a667;
}
.parentDiv .product__item__text .product__color__select label.active:after {
  opacity: 1;
}
.parentDiv .product__item__text .product__color__select label:after {
  position: absolute;
  left: -3px;
  top: -3px;
  height: 18px;
  width: 18px;
  border: 1px solid #b9b9b9;
  content: "";
  border-radius: 50%;
  opacity: 0;
}
.parentDiv .product__item__text .product__color__select label input {
  position: absolute;
  visibility: hidden;
}
.parentDiv

/*---------------------
  Shop
-----------------------*/

.shop__sidebar {
  padding-right: 20px;
}
.parentDiv .shop__sidebar__search {
  margin-bottom: 45px;
}
.parentDiv .shop__sidebar__search form {
  position: relative;
}
.parentDiv .shop__sidebar__search form input {
  width: 100%;
  font-size: 15px;
  color: #b7b7b7;
  padding-left: 20px;
  border: 1px solid #e5e5e5;
  height: 42px;
}
.parentDiv .shop__sidebar__search form input::-webkit-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .shop__sidebar__search form input::-moz-placeholder {
  color: #b7b7b7;
}
.parentDiv .shop__sidebar__search form input:-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .shop__sidebar__search form input::-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .shop__sidebar__search form input::placeholder {
  color: #b7b7b7;
}
.parentDiv .shop__sidebar__search form button {
  color: #b7b7b7;
  font-size: 15px;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  padding: 0 15px;
  top: 0;
  height: 100%;
}
.parentDiv .shop__sidebar__accordion .card {
  border: none;
  border-radius: 0;
  margin-bottom: 25px;
}
.parentDiv .shop__sidebar__accordion .card:last-child {
  margin-bottom: 0;
}
.parentDiv .shop__sidebar__accordion .card:last-child .card-body {
  padding-bottom: 0;
  border-bottom: none;
}
.parentDiv .shop__sidebar__accordion .card-body {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}
.parentDiv .shop__sidebar__accordion .card-heading {
  cursor: pointer;
}
.parentDiv .shop__sidebar__accordion .card-heading a {
  color: #111111;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}
.parentDiv .shop__sidebar__categories ul,
.shop__sidebar__price ul,
.shop__sidebar__brand ul {
  /* height: 225px; */
}
.parentDiv .shop__sidebar__categories ul li,
.shop__sidebar__price ul li,
.shop__sidebar__brand ul li {
  list-style: none;
}
.parentDiv .shop__sidebar__categories ul li a,
.shop__sidebar__price ul li a,
.shop__sidebar__brand ul li a {
  color: #b7b7b7;
  font-size: 15px;
  line-height: 32px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .shop__sidebar__categories ul li a:hover,
.shop__sidebar__price ul li a:hover,
.shop__sidebar__brand ul li a:hover {
  color: #111111;
}
.parentDiv .shop__sidebar__brand ul {
  height: auto;
}
.parentDiv .shop__sidebar__price ul {
  height: auto;
}
.parentDiv .shop__sidebar__size {
  padding-top: 15px;
}
.parentDiv .shop__sidebar__size label {
  color: #111111;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #e5e5e5;
  padding: 6px 25px;
  margin-bottom: 10px;
  margin-right: 5px;
  cursor: pointer;
}
.parentDiv .shop__sidebar__size label.active {
  background: #111111;
  color: #ffffff;
  border-color: #111111;
}
.parentDiv .shop__sidebar__size label input {
  position: absolute;
  visibility: hidden;
}
.parentDiv .shop__sidebar__color {
  padding-top: 15px;
}
.parentDiv .shop__sidebar__color label {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
}
.parentDiv .shop__sidebar__color label.c-1 {
  background: #0b090c;
}
.parentDiv .shop__sidebar__color label.c-2 {
  background: #20315f;
}
.parentDiv .shop__sidebar__color label.c-3 {
  background: #f1af4d;
}
.parentDiv .shop__sidebar__color label.c-4 {
  background: #636068;
}
.parentDiv .shop__sidebar__color label.c-5 {
  background: #57594d;
}
.parentDiv .shop__sidebar__color label.c-6 {
  background: #e8bac4;
}
.parentDiv .shop__sidebar__color label.c-7 {
  background: #d6c1d7;
}
.parentDiv .shop__sidebar__color label.c-8 {
  background: #ed1c24;
}
.parentDiv .shop__sidebar__color label.c-9 {
  background: #ffffff;
}
.parentDiv .shop__sidebar__color label:after {
  position: absolute;
  left: -3px;
  top: -3px;
  height: 36px;
  width: 36px;
  border: 1px solid #e5e5e5;
  content: "";
  border-radius: 50%;
}
.parentDiv .shop__sidebar__color label input {
  position: absolute;
  visibility: hidden;
}
.parentDiv .shop__sidebar__tags {
  padding-top: 15px;
}
.parentDiv .shop__sidebar__tags a {
  color: #404040;
  font-size: 13px;
  font-weight: 700;
  background: #f1f5f8;
  padding: 5px 18px;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 6px;
  margin-bottom: 10px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .shop__sidebar__tags a:hover {
  background: #111111;
  color: #ffffff;
}
.parentDiv .shop__sidebar__accordion .card-heading a:after,
.shop__sidebar__accordion
  .card-heading
  > a.active[aria-expanded="false"]:after {
  content: "";
  font-family: "FontAwesome";
  font-size: 24px;
  font-weight: 700;
  color: #111111;
  position: absolute;
  right: 0;
  top: 2px;
  line-height: 20px;
}
.parentDiv .shop__sidebar__accordion .card-heading.active a:after {
  content: "";
  font-family: "FontAwesome";
  font-size: 24px;
  font-weight: 700;
  color: #111111;
  position: absolute;
  right: 0;
  top: 2px;
  line-height: 20px;
}
.parentDiv .shop__product__option {
  margin-bottom: 45px;
}
.parentDiv .shop__product__option p {
  color: #111111;
  margin-bottom: 0;
}
.parentDiv .shop__product__option__right {
  text-align: right;
}
.parentDiv .shop__product__option__right p {
  display: inline-block;
  margin-bottom: 0;
}
.parentDiv .shop__product__option__right .nice-select {
  float: none;
  display: inline-block;
  padding: 0;
  line-height: 26px;
  height: auto;
  border: none;
  padding-right: 28px;
}
.parentDiv .shop__product__option__right .nice-select:after {
  border-bottom: 1.5px solid #111111;
  border-right: 1.5px solid #111111;
  height: 8px;
  right: 12px;
  width: 8px;
}
.parentDiv .shop__product__option__right .nice-select span {
  color: #111111;
  font-size: 15px;
  font-weight: 700;
}
.parentDiv .shop__product__option__right .nice-select .list {
  border-radius: 0;
}
.parentDiv .product__pagination {
  padding-top: 25px;
  text-align: center;
}
.parentDiv .product__pagination a {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #111111;
  height: 30px;
  width: 30px;
  border: 1px solid transparent;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
}
.parentDiv .product__pagination a.active {
  border-color: #111111;
}
.parentDiv .product__pagination a:hover {
  border-color: #111111;
}
.parentDiv .product__pagination span {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #111111;
  padding-left: 10px;
  padding-right: 15px;
}
.parentDiv

/*---------------------
  Shop
-----------------------*/

.product__details__pic {
  text-align: center;
  background: #f3f2ee;
  padding: 40px 0 60px;
  margin-bottom: 100px;
}
.parentDiv .product__details__pic .nav-tabs {
  border-bottom: none;
  width: 105px;
}
.parentDiv .product__details__pic .nav-tabs .nav-item {
  margin-bottom: 10px;
}
.parentDiv .product__details__pic .nav-tabs .nav-item:last-child {
  margin-bottom: 0;
}
.parentDiv .product__details__pic .nav-tabs .nav-item .nav-link {
  padding: 0;
  display: block;
}
.parentDiv
  .product__details__pic
  .nav-tabs
  .nav-item
  .nav-link
  .product__thumb__pic {
  height: 120px;
  width: 95px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.parentDiv
  .product__details__pic
  .nav-tabs
  .nav-item
  .nav-link
  .product__thumb__pic
  i {
  height: 56px;
  width: 56px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  font-size: 20px;
  color: #ffffff;
  line-height: 48px;
  display: inline-block;
  text-align: center;
}
.parentDiv .product__details__pic .nav-tabs .nav-item.show .nav-link,
.product__details__pic .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
}
.parentDiv .product__details__breadcrumb {
  margin-bottom: 30px;
}
.parentDiv .product__details__breadcrumb a {
  font-size: 15px;
  color: #111111;
  margin-right: 18px;
  display: inline-block;
  position: relative;
}
.parentDiv .product__details__breadcrumb a:after {
  position: absolute;
  right: -14px;
  top: 0;
  content: "";
  font-family: "FontAwesome";
}
.parentDiv .product__details__breadcrumb span {
  font-size: 15px;
  color: #b7b7b7;
  display: inline-block;
}
.parentDiv .product__details__pic__item {
  position: relative;
}
.parentDiv .product__details__pic__item a {
  height: 56px;
  width: 56px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  font-size: 20px;
  color: #ffffff;
  line-height: 48px;
  text-align: center;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -28px;
  margin-left: -28px;
}
.parentDiv .product__details__text {
  text-align: center;
}
.parentDiv .product__details__text h4 {
  color: #111111;
  font-weight: 700;
  margin-bottom: 10px;
}
.parentDiv .product__details__text .rating {
  margin-bottom: 20px;
}
.parentDiv .product__details__text .rating i {
  font-size: 15px;
  color: #f7941d;
  display: inline-block;
  margin-right: -5px;
}
.parentDiv .product__details__text .rating span {
  display: inline-block;
  color: #3d3d3d;
  margin-left: 5px;
}
.parentDiv .product__details__text h3 {
  color: #0d0d0d;
  font-weight: 700;
  margin-bottom: 16px;
}
.parentDiv .product__details__text h3 span {
  color: #b7b7b7;
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
  text-decoration: line-through;
}
.parentDiv .product__details__text p {
  margin-bottom: 35px;
}
.parentDiv .product__details__option {
  margin-bottom: 30px;
}
.parentDiv .product__details__option__size {
  display: inline-block;
  margin-right: 50px;
}
.parentDiv .product__details__option__size span {
  color: #111111;
  display: inline-block;
  margin-right: 10px;
}
.parentDiv .product__details__option__size label {
  color: #111111;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #e5e5e5;
  padding: 6px 15px;
  margin-bottom: 0;
  margin-right: 5px;
  cursor: pointer;
}
.parentDiv .product__details__option__size label.active {
  background: #111111;
  color: #ffffff;
  border-color: #111111;
}
.parentDiv .product__details__option__size label input {
  position: absolute;
  visibility: hidden;
}
.parentDiv .product__details__option__color {
  display: inline-block;
  position: relative;
  top: 10px;
}
.parentDiv .product__details__option__color span {
  color: #111111;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: -9px;
}
.parentDiv .product__details__option__color label {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  margin-bottom: 0;
  display: inline-block;
  cursor: pointer;
}
.parentDiv .product__details__option__color label.c-1 {
  background: #0b090c;
}
.parentDiv .product__details__option__color label.c-2 {
  background: #20315f;
}
.parentDiv .product__details__option__color label.c-3 {
  background: #f1af4d;
}
.parentDiv .product__details__option__color label.c-4 {
  background: #ed1c24;
}
.parentDiv .product__details__option__color label.c-9 {
  background: #ffffff;
}
.parentDiv .product__details__option__color label:after {
  position: absolute;
  left: -3px;
  top: -3px;
  height: 36px;
  width: 36px;
  border: 1px solid #e5e5e5;
  content: "";
  border-radius: 50%;
}
.parentDiv .product__details__option__color label input {
  position: absolute;
  visibility: hidden;
}
.parentDiv .product__details__cart__option {
  margin-bottom: 25px;
}
.parentDiv .product__details__cart__option .quantity {
  display: inline-block;
  margin-right: 20px;
}
.parentDiv .product__details__cart__option .quantity .pro-qty {
  width: 100px;
  height: 40px;
  border: 1px solid #e5e5e5;
  position: relative;
}
.parentDiv .product__details__cart__option .quantity .pro-qty input {
  color: #0d0d0d;
  font-size: 15px;
  font-weight: 700;
  width: 70px;
  height: 100%;
  text-align: center;
  border: none;
}
.parentDiv .product__details__cart__option .quantity .pro-qty .qtybtn {
  font-size: 18px;
  color: #0d0d0d;
  position: absolute;
  right: 15px;
  top: 3px;
  height: 10px;
  width: 10px;
  cursor: pointer;
  font-weight: 600;
}
.parentDiv .product__details__cart__option .quantity .pro-qty .qtybtn.inc {
  top: 16px;
}
.parentDiv .product__details__btns__option {
  margin-bottom: 40px;
}
.parentDiv .product__details__btns__option a {
  display: inline-block;
  font-size: 13px;
  color: #3d3d3d;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 20px;
}
.parentDiv .product__details__btns__option a:last-child {
  margin-right: 0;
}
.parentDiv .product__details__last__option h5 {
  color: #111111;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  margin-bottom: 26px;
}
.parentDiv .product__details__last__option h5 span {
  background: #ffffff;
  padding: 0 30px;
}
.parentDiv .product__details__last__option h5:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  height: 1px;
  width: 460px;
  background: #e5e5e5;
  content: "";
  z-index: -1;
  margin: 0 auto;
}
.parentDiv .product__details__last__option ul {
  padding-top: 40px;
}
.parentDiv .product__details__last__option ul li {
  list-style: none;
  font-size: 15px;
  color: #111111;
  font-weight: 700;
  line-height: 30px;
}
.parentDiv .product__details__last__option ul li span {
  font-weight: 400;
  color: #b7b7b7;
}
.parentDiv .product__details__tab {
  padding-top: 60px;
}
.parentDiv .product__details__tab .nav-tabs {
  border-bottom: 1px solid #e5e5e5;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.parentDiv .product__details__tab .nav-tabs .nav-item {
  margin-right: 50px;
}
.parentDiv .product__details__tab .nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.parentDiv .product__details__tab .nav-tabs .nav-item .nav-link {
  font-size: 20px;
  color: #b7b7b7;
  padding: 0;
  border: none;
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
}
.parentDiv .product__details__tab .nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #e53637;
}
.parentDiv .product__details__tab__content {
  padding-top: 35px;
}
.parentDiv .note {
  color: #111111;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 25px;
}
.parentDiv .product__details__tab__content__item {
  margin-bottom: 30px;
}
.parentDiv .product__details__tab__content__item:last-child {
  margin-bottom: 0;
}
.parentDiv .product__details__tab__content__item h5 {
  color: #111111;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
}
.parentDiv .product__details__tab__content__item p {
  margin-bottom: 0;
}
.parentDiv

/*---------------------
  Related
-----------------------*/

.related {
  padding-bottom: 55px;
}
.parentDiv .related-title {
  color: #111111;
  font-weight: 700;
  margin-bottom: 45px;
  text-align: center;
}
.parentDiv

/*---------------------
  Footer
-----------------------*/

.footer {
  background: #111111;
  padding-top: 70px;
  bottom: 0px;
  position: relative !important;

}
.parentDiv .footer__about {
  margin-bottom: 30px;
}
.parentDiv .footer__about .footer__logo {
  margin-bottom: 30px;
}
.parentDiv .footer__about .footer__logo a {
  display: inline-block;
}
.parentDiv .footer__about p {
  color: #b7b7b7;
  margin-bottom: 30px;
}
.parentDiv .footer__widget {
  margin-bottom: 30px;
}
.parentDiv .footer__widget h6 {
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
}
.parentDiv .footer__widget ul li {
  line-height: 36px;
  list-style: none;
}
.parentDiv .footer__widget ul li a {
  color: #b7b7b7;
  font-size: 15px;
}
.parentDiv .footer__widget .footer__newslatter p {
  color: #b7b7b7;
}
.parentDiv .footer__widget .footer__newslatter form {
  position: relative;
}
.parentDiv .footer__widget .footer__newslatter form input {
  width: 100%;
  font-size: 15px;
  color: #3d3d3d;
  background: transparent;
  border: none;
  padding: 15px 0;
  border-bottom: 2px solid #ffffff;
}
.parentDiv
  .footer__widget
  .footer__newslatter
  form
  input::-webkit-input-placeholder {
  color: #3d3d3d;
}
.parentDiv .footer__widget .footer__newslatter form input::-moz-placeholder {
  color: #3d3d3d;
}
.parentDiv
  .footer__widget
  .footer__newslatter
  form
  input:-ms-input-placeholder {
  color: #3d3d3d;
}
.parentDiv
  .footer__widget
  .footer__newslatter
  form
  input::-ms-input-placeholder {
  color: #3d3d3d;
}
.parentDiv .footer__widget .footer__newslatter form input::placeholder {
  color: #3d3d3d;
}
.parentDiv .footer__widget .footer__newslatter form button {
  color: #b7b7b7;
  font-size: 16px;
  right: 5px;
  top: 0;
  height: 100%;
  background: transparent;
  border: none;
}
.parentDiv .footer__copyright__text {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
  margin-top: 40px;
}
.parentDiv .footer__copyright__text p {
  color: #b7b7b7;
  margin-bottom: 0;
}
.parentDiv .footer__copyright__text p i {
  color: #e53637;
}
.parentDiv .footer__copyright__text p a {
  color: #e53637;
}
.parentDiv

/*---------------------
  Breadcrumb
-----------------------*/

.breadcrumb-option {
  background: #f3f2ee;
  padding: 40px 0;
}
.parentDiv .breadcrumb__text h4 {
  color: #111111;
  font-weight: 700;
  margin-bottom: 8px;
}
.parentDiv .breadcrumb__links a {
  font-size: 15px;
  color: #111111;
  margin-right: 18px;
  display: inline-block;
  position: relative;
}
.parentDiv .breadcrumb__links a:after {
  position: absolute;
  right: -14px;
  top: 0;
  content: "";
  font-family: "FontAwesome";
}
.parentDiv .breadcrumb__links span {
  font-size: 15px;
  color: #b7b7b7;
  display: inline-block;
}
.parentDiv

/*---------------------
  Breadcrumb Blog
-----------------------*/

.breadcrumb-blog {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 350px;
}
.parentDiv .breadcrumb-blog h2 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
}
.parentDiv

/*---------------------
  About
-----------------------*/

.about {
  padding-bottom: 70px;
}
.parentDiv .about__pic {
  margin-bottom: 35px;
}
.parentDiv .about__pic img {
  min-width: 100%;
}
.parentDiv .about__item {
  margin-bottom: 30px;
}
.parentDiv .about__item h4 {
  color: #111111;
  font-weight: 700;
  margin-bottom: 10px;
}
.parentDiv .about__item p {
  margin-bottom: 0;
}
.parentDiv

/*---------------------
  Testimonial
-----------------------*/

.testimonial {
  background: #f3f2ee;
}
.parentDiv .testimonial__text {
  text-align: center;
  padding: 130px 150px 175px;
}
.parentDiv .testimonial__text span {
  color: #e53637;
  font-size: 72px;
}
.parentDiv .testimonial__text p {
  color: #111111;
  font-size: 20px;
  font-style: italic;
  line-height: 30px;
  padding-top: 12px;
  margin-bottom: 25px;
}
.parentDiv .testimonial__author {
  display: inline-block;
}
.parentDiv .testimonial__author__pic {
  float: left;
  margin-right: 20px;
}
.parentDiv .testimonial__author__pic img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.parentDiv .testimonial__author__text {
  overflow: hidden;
  padding-top: 3px;
}
.parentDiv .testimonial__author__text h5 {
  color: #111111;
  font-weight: 700;
  margin-bottom: 5px;
}
.parentDiv .testimonial__author__text p {
  color: #b7b7b7;
  margin-bottom: 0 !important;
  padding-top: 0;
}
.parentDiv .testimonial__pic {
  height: 600px;
}
.parentDiv

/*---------------------
  Counter
-----------------------*/

.counter {
  padding-bottom: 0;
}
.parentDiv .counter .container {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 70px;
}
.parentDiv .counter__item {
  margin-bottom: 30px;
  overflow: hidden;
}
.parentDiv .counter__item .counter__item__number {
  float: left;
  margin-right: 15px;
}
.parentDiv .counter__item .counter__item__number h2 {
  color: #111111;
  font-weight: 700;
  font-size: 60px;
  line-height: 50px;
  display: inline-block;
}
.parentDiv .counter__item .counter__item__number strong {
  color: #111111;
  font-weight: 700;
  font-size: 60px;
  line-height: 50px;
  display: inline-block;
}
.parentDiv .counter__item span {
  display: block;
  color: #3d3d3d;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  overflow: hidden;
}
.parentDiv

/*---------------------
  Testimonial
-----------------------*/

.team {
  padding-bottom: 70px;
}
.parentDiv .team__item {
  margin-bottom: 30px;
}
.parentDiv .team__item img {
  min-width: 100%;
  margin-bottom: 25px;
}
.parentDiv .team__item h4 {
  color: #111111;
  font-weight: 700;
  margin-bottom: 8px;
}
.parentDiv .team__item span {
  font-size: 15px;
  display: block;
  color: #b7b7b7;
}
.parentDiv

/*---------------------
  Clients
-----------------------*/

.clients {
  padding-top: 0;
  padding-bottom: 25px;
}
.parentDiv .client__item {
  display: block;
  margin-bottom: 75px;
  text-align: center;
}
.parentDiv

/*---------------------
  Shopping Cart
-----------------------*/

.shopping__cart__table {
  margin-bottom: 30px;
}
.parentDiv .shopping__cart__table table {
  width: 100%;
}
.parentDiv .shopping__cart__table table thead {
  border-bottom: 1px solid #f2f2f2;
}
.parentDiv .shopping__cart__table table thead tr th {
  color: #111111;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 25px;
}
.parentDiv .shopping__cart__table table tbody tr {
  border-bottom: 1px solid #f2f2f2;
}
.parentDiv .shopping__cart__table table tbody tr td {
  padding-bottom: 30px;
  padding-top: 30px;
}
.parentDiv .shopping__cart__table table tbody tr td.product__cart__item {
  width: 400px;
}
.parentDiv
  .shopping__cart__table
  table
  tbody
  tr
  td.product__cart__item
  .product__cart__item__pic {
  float: left;
  margin-right: 30px;
}
.parentDiv
  .shopping__cart__table
  table
  tbody
  tr
  td.product__cart__item
  .product__cart__item__text {
  overflow: hidden;
  padding-top: 21px;
}
.parentDiv
  .shopping__cart__table
  table
  tbody
  tr
  td.product__cart__item
  .product__cart__item__text
  h6 {
  color: #111111;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
}
.parentDiv
  .shopping__cart__table
  table
  tbody
  tr
  td.product__cart__item
  .product__cart__item__text
  h5 {
  color: #0d0d0d;
  font-weight: 700;
}
.parentDiv .shopping__cart__table table tbody tr td.quantity__item {
  width: 175px;
}
.parentDiv
  .shopping__cart__table
  table
  tbody
  tr
  td.quantity__item
  .quantity
  .pro-qty-2 {
  width: 80px;
}
.parentDiv
  .shopping__cart__table
  table
  tbody
  tr
  td.quantity__item
  .quantity
  .pro-qty-2
  input {
  width: 50px;
  border: none;
  text-align: center;
  color: #111111;
  font-size: 16px;
}
.parentDiv
  .shopping__cart__table
  table
  tbody
  tr
  td.quantity__item
  .quantity
  .pro-qty-2
  .qtybtn {
  font-size: 16px;
  color: #888888;
  width: 10px;
  cursor: pointer;
}
.parentDiv .shopping__cart__table table tbody tr td.cart__price {
  color: #111111;
  font-size: 18px;
  font-weight: 700;
  width: 140px;
}
.parentDiv .shopping__cart__table table tbody tr td.cart__close i {
  font-size: 18px;
  color: #111111;
  height: 40px;
  width: 40px;
  background: #f3f2ee;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
}
.parentDiv .continue__btn.update__btn {
  text-align: right;
}
.parentDiv .continue__btn.update__btn a {
  color: #ffffff;
  background: #111111;
  border-color: #111111;
}
.parentDiv .continue__btn.update__btn a i {
  margin-right: 5px;
}
.parentDiv .continue__btn a {
  color: #111111;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  border: 1px solid #e1e1e1;
  padding: 14px 35px;
  display: inline-block;
}
.parentDiv .cart__discount {
  margin-bottom: 60px;
}
.parentDiv .cart__discount h6 {
  color: #111111;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 35px;
}
.parentDiv .cart__discount form {
  position: relative;
}
.parentDiv .cart__discount form input {
  font-size: 14px;
  color: #b7b7b7;
  height: 50px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
}
.parentDiv .cart__discount form input::-webkit-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .cart__discount form input::-moz-placeholder {
  color: #b7b7b7;
}
.parentDiv .cart__discount form input:-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .cart__discount form input::-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .cart__discount form input::placeholder {
  color: #b7b7b7;
}
.parentDiv .cart__discount form button {
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: #111111;
  padding: 0 30px;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.parentDiv .cart__total {
  background: #f3f2ee;
  padding: 35px 40px 40px;
}
.parentDiv .cart__total h6 {
  color: #111111;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.parentDiv .cart__total ul {
  margin-bottom: 25px;
}
.parentDiv .cart__total ul li {
  list-style: none;
  font-size: 16px;
  color: #444444;
  line-height: 40px;
  overflow: hidden;
}
.parentDiv .cart__total ul li span {
  font-weight: 700;
  color: #e53637;
  float: right;
}
.parentDiv .cart__total .primary-btn {
  display: block;
  padding: 12px 10px;
  text-align: center;
  letter-spacing: 2px;
}
.parentDiv

/*---------------------
  Checkout
-----------------------*/

.coupon__code {
  color: #0d0d0d;
  font-size: 14px;
  border-top: 2px solid #77b527;
  background: #f5f5f5;
  padding: 23px 30px 18px;
  margin-bottom: 50px;
}
.parentDiv .coupon__code span {
  margin-right: 15px;
}
.parentDiv .coupon__code a {
  color: #0d0d0d;
}
.parentDiv .checkout__title {
  color: #111111;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 25px;
  margin-bottom: 30px;
}
.parentDiv .checkout__input {
  margin-bottom: 6px;
}
.parentDiv .checkout__input p {
  color: #111111;
  margin-bottom: 12px;
}
.parentDiv .checkout__input p span {
  color: #e53637;
}
.parentDiv .checkout__input input {
  height: 50px;
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 14px;
  color: #b7b7b7;
  padding-left: 20px;
  margin-bottom: 20px;
}
.parentDiv .checkout__input input::-webkit-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .checkout__input input::-moz-placeholder {
  color: #b7b7b7;
}
.parentDiv .checkout__input input:-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .checkout__input input::-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .checkout__input input::placeholder {
  color: #b7b7b7;
}
.parentDiv .checkout__input__checkbox label {
  font-size: 15px;
  color: #0d0d0d;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  margin-bottom: 16px;
  display: block;
}
.parentDiv .checkout__input__checkbox label input {
  position: absolute;
  visibility: hidden;
}
.parentDiv .checkout__input__checkbox label input:checked ~ .checkmark {
  border-color: #e53637;
}
.parentDiv .checkout__input__checkbox label input:checked ~ .checkmark:after {
  opacity: 1;
}
.parentDiv .checkout__input__checkbox label .checkmark {
  position: absolute;
  left: 0;
  top: 3px;
  height: 14px;
  width: 14px;
  border: 1.5px solid #d7d7d7;
  content: "";
  border-radius: 2px;
}
.parentDiv .checkout__input__checkbox label .checkmark:after {
  position: absolute;
  left: 1px;
  top: -3px;
  width: 14px;
  height: 7px;
  border: solid #e53637;
  border-width: 1.5px 1.5px 0px 0px;
  -webkit-transform: rotate(127deg);
  -ms-transform: rotate(127deg);
  transform: rotate(127deg);
  content: "";
  opacity: 0;
}
.parentDiv .checkout__input__checkbox p {
  color: #0d0d0d;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 22px;
}
.parentDiv .checkout__order {
  background: #f3f2ee;
  padding: 30px;
}
.parentDiv .checkout__order .order__title {
  color: #111111;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 25px;
  margin-bottom: 30px;
}
.parentDiv .checkout__order p {
  color: #444444;
  font-size: 16px;
  line-height: 28px;
}
.parentDiv .checkout__order .site-btn {
  width: 100%;
  margin-top: 8px;
}
.parentDiv .checkout__order__products {
  font-size: 16px;
  color: #111111;
  overflow: hidden;
  margin-bottom: 18px;
}
.parentDiv .checkout__order__products span {
  float: right;
}
.parentDiv .checkout__total__products {
  margin-bottom: 20px;
}
.parentDiv .checkout__total__products li {
  font-size: 16px;
  color: #444444;
  list-style: none;
  line-height: 26px;
  overflow: hidden;
  margin-bottom: 15px;
}
.parentDiv .checkout__total__products li:last-child {
  margin-bottom: 0;
}
.parentDiv .checkout__total__products li span {
  color: #111111;
  float: right;
}
.parentDiv .checkout__total__all {
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  padding: 15px 0;
  margin-bottom: 26px;
}
.parentDiv .checkout__total__all li {
  list-style: none;
  font-size: 16px;
  color: #111111;
  line-height: 40px;
  overflow: hidden;
}
.parentDiv .checkout__total__all li span {
  color: #e53637;
  font-weight: 700;
  float: right;
}
.parentDiv

/*---------------------
    Blog
-----------------------*/

.blog {
  padding-bottom: 55px;
}
.parentDiv .latest {
  padding-bottom: 55px;
}
.parentDiv .blog__item {
  margin-bottom: 45px;
}
.parentDiv .blog__item:hover a::after {
  width: 40px;
  background: #e53637;
}
.parentDiv .blog__item:hover .blog__item__text {
  -webkit-box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.05);
  box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.05);
}
.parentDiv .blog__item__pic {
  height: 270px;
}
.parentDiv .blog__item__text {
  padding: 30px 30px 25px;
  margin: 0 30px;
  margin-top: -35px;
  background: #ffffff;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .blog__item__text span {
  color: #3d3d3d;
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
}
.parentDiv .blog__item__text span img {
  margin-right: 6px;
}
.parentDiv .blog__item__text h5 {
  color: #0d0d0d;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}
.parentDiv .blog__item__text a {
  display: inline-block;
  color: #111111;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding: 3px 0;
  position: relative;
}
.parentDiv .blog__item__text a:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #111111;
  content: "";
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv

/*---------------------
  Blog Sidebar
-----------------------*/

.blog__sidebar__item {
  text-align: center;
  margin-bottom: 65px;
}
.parentDiv .blog__sidebar__item:last-child {
  margin-bottom: 0;
}
.parentDiv .blog__sidebar__item form input {
  height: 50px;
  font-size: 15px;
  color: #444444;
  padding-left: 20px;
  border: 1px solid #e1e1e1;
  width: 100%;
  margin-bottom: 20px;
}
.parentDiv .blog__sidebar__item form input::-webkit-input-placeholder {
  color: #444444;
}
.parentDiv .blog__sidebar__item form input::-moz-placeholder {
  color: #444444;
}
.parentDiv .blog__sidebar__item form input:-ms-input-placeholder {
  color: #444444;
}
.parentDiv .blog__sidebar__item form input::-ms-input-placeholder {
  color: #444444;
}
.parentDiv .blog__sidebar__item form input::placeholder {
  color: #444444;
}
.parentDiv .blog__sidebar__title {
  text-align: center;
  margin-bottom: 35px;
}
.parentDiv .blog__sidebar__title h4 {
  color: #111111;
  font-weight: 700;
  position: relative;
  padding-bottom: 20px;
}
.parentDiv .blog__sidebar__title h4::before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 70px;
  background: #e1e1e1;
  content: "";
  margin: 0 auto;
}
.parentDiv .blog__sidebar__social a {
  display: inline-block;
  font-size: 18px;
  color: #111111;
  width: 50px;
  height: 50px;
  background: #f2f2f2;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  margin-right: 6px;
}
.parentDiv .blog__sidebar__social a:last-child {
  margin-right: 6px;
}
.parentDiv .recent__item {
  display: block;
  overflow: hidden;
  margin-bottom: 25px;
  text-align: left;
}
.parentDiv .recent__item__pic {
  float: left;
  margin-right: 25px;
}
.parentDiv .recent__item__text {
  overflow: hidden;
}
.parentDiv .recent__item__text h6 {
  color: #111111;
  line-height: 21px;
  font-weight: 700;
}
.parentDiv .recent__item__text span {
  font-size: 13px;
  color: #888888;
}
.parentDiv

/*---------------------
  Blog Hero
-----------------------*/

.blog-hero {
  background: #f3f2ee;
  padding-top: 54px;
  padding-bottom: 190px;
}
.parentDiv .blog__hero__text h2 {
  color: #111111;
  font-size: 42px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 18px;
}
.parentDiv .blog__hero__text ul li {
  list-style: none;
  font-size: 15px;
  color: #3d3d3d;
  display: inline-block;
  margin-right: 40px;
  position: relative;
}
.parentDiv .blog__hero__text ul li:after {
  position: absolute;
  right: -25px;
  top: 0;
  content: "|";
}
.parentDiv .blog__hero__text ul li:last-child {
  margin-right: 0;
}
.parentDiv .blog__hero__text ul li:last-child:after {
  display: none;
}
.parentDiv

/*---------------------
  Blog Details
-----------------------*/

.blog-details {
  margin-top: -115px;
  padding-top: 0;
}
.parentDiv .blog__details__content {
  position: relative;
}
.parentDiv .blog__details__pic {
  margin-bottom: 60px;
}
.parentDiv .blog__details__pic img {
  min-width: 100%;
  border-radius: 5px;
}
.parentDiv .blog__details__share {
  text-align: center;
  position: absolute;
  left: -120px;
  top: 0;
}
.parentDiv .blog__details__share span {
  color: #111111;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
}
.parentDiv .blog__details__share ul li {
  list-style: none;
  margin-bottom: 15px;
}
.parentDiv .blog__details__share ul li a {
  color: #ffffff;
  font-size: 18px;
  height: 46px;
  display: inline-block;
  width: 46px;
  border-radius: 50%;
  line-height: 46px;
  text-align: center;
  background: #4267b2;
}
.parentDiv .blog__details__share ul li a.twitter {
  background: #1da1f2;
}
.parentDiv .blog__details__share ul li a.youtube {
  background: #fe0003;
}
.parentDiv .blog__details__share ul li a.linkedin {
  background: #0173b2;
}
.parentDiv .blog__details__text {
  margin-bottom: 50px;
}
.parentDiv .blog__details__text p {
  font-size: 18px;
  line-height: 34px;
}
.parentDiv .blog__details__text p:last-child {
  margin-bottom: 0;
}
.parentDiv .blog__details__quote {
  background: #f3f2ee;
  padding: 50px 40px 35px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 45px;
}
.parentDiv .blog__details__quote i {
  font-size: 16px;
  color: #ffffff;
  height: 50px;
  width: 50px;
  background: #e53637;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  position: absolute;
  left: 40px;
  top: -25px;
}
.parentDiv .blog__details__quote p {
  color: #111111;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  margin-bottom: 20px;
}
.parentDiv .blog__details__quote h6 {
  color: #e53637;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}
.parentDiv .blog__details__option {
  border-top: 1px solid #e5e5e5;
  padding-top: 15px;
  margin-bottom: 70px;
}
.parentDiv .blog__details__author__pic {
  display: inline-block;
  margin-right: 15px;
}
.parentDiv .blog__details__author__pic img {
  height: 46px;
  width: 46px;
  border-radius: 50%;
}
.parentDiv .blog__details__author__text {
  display: inline-block;
}
.parentDiv .blog__details__author__text h5 {
  color: #111111;
  font-weight: 700;
}
.parentDiv .blog__details__tags {
  text-align: right;
}
.parentDiv .blog__details__tags a {
  display: inline-block;
  color: #111111;
  font-weight: 700;
  margin-right: 10px;
}
.parentDiv .blog__details__tags a:last-child {
  margin-right: 0;
}
.parentDiv .blog__details__btns {
  margin-bottom: 40px;
}
.parentDiv .blog__details__btns__item {
  display: block;
  border: 1px solid #ebebeb;
  padding: 25px 30px 30px;
  margin-bottom: 30px;
}
.parentDiv .blog__details__btns__item.blog__details__btns__item--next {
  text-align: right;
}
.parentDiv .blog__details__btns__item.blog__details__btns__item--next p span {
  margin-right: 0;
  margin-left: 5px;
}
.parentDiv .blog__details__btns__item p {
  color: #b7b7b7;
  margin-bottom: 10px;
}
.parentDiv .blog__details__btns__item p span {
  font-size: 20px;
  position: relative;
  top: 4px;
  margin-right: 5px;
}
.parentDiv .blog__details__btns__item h5 {
  color: #111111;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}
.parentDiv .blog__details__comment h4 {
  color: #333333;
  font-weight: 700;
  margin-bottom: 35px;
  text-align: center;
}
.parentDiv .blog__details__comment form input {
  height: 50px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  font-size: 15px;
  color: #b7b7b7;
  margin-bottom: 30px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .blog__details__comment form input::-webkit-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form input::-moz-placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form input:-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form input::-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form input::placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form input:focus {
  border-color: #000000;
}
.parentDiv .blog__details__comment form textarea {
  height: 120px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  padding-top: 12px;
  font-size: 15px;
  color: #b7b7b7;
  margin-bottom: 24px;
  resize: none;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}
.parentDiv .blog__details__comment form textarea::-webkit-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form textarea::-moz-placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form textarea:-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form textarea::-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form textarea::placeholder {
  color: #b7b7b7;
}
.parentDiv .blog__details__comment form textarea:focus {
  border-color: #000000;
}
.parentDiv .blog__details__comment form button {
  letter-spacing: 4px;
  padding: 14px 35px;
}
.parentDiv

/*---------------------
  Map
-----------------------*/

.map {
  height: 500px;
}
.parentDiv .map iframe {
  width: 100%;
}
.parentDiv

/*---------------------
  Contact
-----------------------*/

.contact__text .section-title {
  text-align: left;
  margin-bottom: 40px;
}
.parentDiv .contact__text .section-title h2 {
  font-size: 48px;
  margin-bottom: 18px;
}
.parentDiv .contact__text .section-title p {
  color: #707070;
  line-height: 26px;
  margin-bottom: 0;
}
.parentDiv .contact__text ul li {
  list-style: none;
  margin-bottom: 26px;
}
.parentDiv .contact__text ul li:last-child {
  margin-bottom: 0;
}
.parentDiv .contact__text ul li h4 {
  color: #111111;
  font-weight: 700;
  margin-bottom: 8px;
}
.parentDiv .contact__text ul li p {
  line-height: 27px;
  margin-bottom: 0;
}
.parentDiv .contact__form input {
  height: 50px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  font-size: 15px;
  color: #b7b7b7;
  margin-bottom: 30px;
}
.parentDiv .contact__form input::-webkit-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form input::-moz-placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form input:-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form input::-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form input::placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form textarea {
  height: 150px;
  width: 100%;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  padding-top: 12px;
  font-size: 15px;
  color: #b7b7b7;
  margin-bottom: 24px;
  resize: none;
}
.parentDiv .contact__form textarea::-webkit-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form textarea::-moz-placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form textarea:-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form textarea::-ms-input-placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form textarea::placeholder {
  color: #b7b7b7;
}
.parentDiv .contact__form button {
  letter-spacing: 4px;
  padding: 14px 35px;
}
/*--------------------------------- Responsive Media Quaries -----------------------------*/

@media only screen and (min-width: 1200px) {
  .parentDiv .container {
    max-width: 1170px;
  }
}

/* Medium Device = 1200px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .parentDiv .categories__text h2 {
    font-size: 26px;
  }
  .parentDiv .header__menu ul li {
    margin-right: 38px;
  }
  .parentDiv .hero__slider.owl-carousel .owl-nav button {
    left: 2px;
  }
  .parentDiv .hero__slider.owl-carousel .owl-nav button.owl-next {
    right: 2px;
  }
  .parentDiv .testimonial__text {
    padding: 130px 45px 175px;
  }
}

/* Tablet Device = 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .parentDiv .header__menu ul li {
    margin-right: 10px;
  }
  .parentDiv .header__nav__option a {
    margin-right: 10px;
  }
  .parentDiv .header__nav__option .price {
    margin-left: 0;
  }
  .parentDiv .hero__slider.owl-carousel .owl-nav button {
    left: 2px;
  }
  .parentDiv .hero__slider.owl-carousel .owl-nav button.owl-next {
    right: 2px;
  }
  .parentDiv .banner__item.banner__item--middle {
    margin-top: 0;
  }
  .parentDiv .banner__item.banner__item--last {
    margin-top: 0;
  }
  .parentDiv .banner__item {
    margin-bottom: 40px;
  }
  .parentDiv .banner {
    padding-bottom: 60px;
  }
  .parentDiv .categories__text {
    margin-bottom: 40px;
  }
  .parentDiv .categories__hot__deal {
    margin-bottom: 40px;
  }
  .parentDiv .instagram__text {
    padding-top: 70px;
  }
  .parentDiv .shop__sidebar {
    padding-right: 0;
    padding-top: 40px;
  }
  .parentDiv .cart__discount {
    margin-top: 40px;
  }
  .parentDiv .testimonial__text {
    padding: 100px 105px 100px;
  }
  .parentDiv .blog__details__share {
    position: relative;
    left: 0;
    margin-bottom: 18px;
  }
  .parentDiv .blog__details__share span {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .parentDiv .blog__details__share ul li {
    margin-bottom: 15px;
    display: inline-block;
    margin-right: 10px;
  }
  .parentDiv .blog__details__share ul li:last-child {
    margin-right: 0;
  }
}

/* Wide Mobile = 480px */

@media only screen and (max-width: 767px) {
  .parentDiv .canvas__open {
    display: block;
    font-size: 22px;
    color: #111111;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border: 1px solid #111111;
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 25px;
  }
  .parentDiv .offcanvas-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    content: "";
    z-index: 98;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    visibility: hidden;
  }
  .parentDiv .offcanvas-menu-overlay.active {
    visibility: visible;
  }
  .parentDiv .offcanvas-menu-wrapper {
    position: fixed;
    left: -300px;
    width: 300px;
    height: 100%;
    background: #ffffff;
    padding: 50px 20px 30px 30px;
    display: block;
    z-index: 99;
    overflow-y: auto;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    opacity: 0;
  }
  .parentDiv .offcanvas-menu-wrapper.active {
    opacity: 1;
    left: 0;
  }
  .parentDiv .offcanvas__menu {
    display: none;
  }
  .parentDiv .slicknav_btn {
    display: none;
  }
  .parentDiv .slicknav_menu {
    background: transparent;
    padding: 0;
    margin-bottom: 20px;
  }
  .parentDiv .slicknav_nav ul {
    margin: 0;
  }
  .parentDiv .slicknav_nav .slicknav_row,
  .slicknav_nav a {
    padding: 7px 0;
    margin: 0;
    color: #111111;
    font-weight: 600;
  }
  .parentDiv .slicknav_nav .slicknav_arrow {
    color: #111111;
  }
  .parentDiv .slicknav_nav .slicknav_row:hover {
    border-radius: 0;
    background: transparent;
    color: #111111;
  }
  .parentDiv .slicknav_nav a:hover {
    border-radius: 0;
    background: transparent;
    color: #111111;
  }
  .parentDiv .slicknav_nav {
    display: block !important;
  }
  .parentDiv .offcanvas__option {
    text-align: center;
    margin-bottom: 30px;
  }
  .parentDiv .offcanvas__links {
    display: inline-block;
    margin-right: 25px;
  }
  .parentDiv .offcanvas__links a {
    color: #111111;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 16px;
    display: inline-block;
    font-weight: 600;
  }
  .parentDiv .offcanvas__links a:last-child {
    margin-right: 0;
  }
  .parentDiv .offcanvas__top__hover {
    display: inline-block;
    position: relative;
  }
  .parentDiv .offcanvas__top__hover:hover ul {
    top: 24px;
    opacity: 1;
    visibility: visible;
  }
  .parentDiv .offcanvas__top__hover span {
    color: #111111;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
  }
  .parentDiv .offcanvas__top__hover span i {
    font-size: 20px;
    position: relative;
    top: 3px;
    right: 2px;
  }
  .parentDiv .offcanvas__top__hover ul {
    background: #111111;
    display: inline-block;
    padding: 2px 0;
    position: absolute;
    left: 0;
    top: 44px;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    -webkit-transition: all, 0.3s;
    -o-transition: all, 0.3s;
    transition: all, 0.3s;
  }
  .parentDiv .offcanvas__top__hover ul li {
    list-style: none;
    font-size: 13px;
    color: #ffffff;
    padding: 2px 15px;
    cursor: pointer;
  }
  .parentDiv .offcanvas__nav__option {
    text-align: center;
    margin-bottom: 25px;
  }
  .parentDiv .offcanvas__nav__option a {
    display: inline-block;
    margin-right: 26px;
    position: relative;
  }
  .parentDiv .offcanvas__nav__option a span {
    color: #0d0d0d;
    font-size: 11px;
    position: absolute;
    left: 5px;
    top: 8px;
  }
  .parentDiv .offcanvas__nav__option a:last-child {
    margin-right: 0;
  }
  .parentDiv .offcanvas__nav__option .price {
    font-size: 15px;
    color: #111111;
    font-weight: 700;
    display: inline-block;
    margin-left: -20px;
    position: relative;
    top: 3px;
  }
  .parentDiv .offcanvas__text p {
    color: #111111;
    margin-bottom: 0;
  }
  .parentDiv .header__top {
    display: none;
  }
  .parentDiv .header .container {
    position: relative;
  }
  .parentDiv .header__menu {
    display: none;
  }
  .parentDiv .header__nav__option {
    display: none;
  }
  .parentDiv .search-model-form input {
    width: 100%;
    font-size: 24px;
  }
  .parentDiv .hero__slider.owl-carousel .owl-nav button {
    left: 15px;
    top: 80%;
  }
  .parentDiv .hero__slider.owl-carousel .owl-nav button.owl-next {
    left: 75px;
    right: 0;
  }
  .parentDiv .banner__item.banner__item--middle {
    margin-top: 0;
  }
  .parentDiv .banner__item.banner__item--last {
    margin-top: 0;
  }
  .parentDiv .banner__item {
    margin-bottom: 40px;
  }
  .parentDiv .banner {
    padding-bottom: 60px;
  }
  .parentDiv .banner__item__pic {
    float: none;
  }
  .parentDiv .banner__item__pic img {
    min-width: 100%;
  }
  .parentDiv .banner__item__text {
    max-width: 100%;
    position: relative;
    top: 0;
    padding-top: 22px;
  }
  .parentDiv .filter__controls li {
    margin-right: 15px;
  }
  .parentDiv .categories__text {
    margin-bottom: 40px;
  }
  .parentDiv .categories__hot__deal {
    margin-bottom: 40px;
  }
  .parentDiv .instagram__text {
    padding-top: 70px;
  }
  .parentDiv .instagram__pic__item {
    width: 50%;
  }
  .parentDiv .shop__product__option__right {
    text-align: left;
    padding-top: 20px;
  }
  .parentDiv .shop__sidebar {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .parentDiv .testimonial__text {
    padding: 100px 40px 100px;
  }
  .parentDiv .product__details__breadcrumb {
    text-align: left;
  }
  .parentDiv .product__details__pic .nav-tabs {
    width: auto;
    margin-bottom: 40px;
  }
  .parentDiv .product__details__pic .nav-tabs .nav-item {
    margin-bottom: 0;
    margin-right: 10px;
  }
  .parentDiv .product__details__option__size {
    display: block;
    margin-right: 0;
    margin-bottom: 25px;
  }
  .parentDiv .product__details__last__option h5:before {
    width: 440px;
  }
  .parentDiv .product__details__tab .nav-tabs .nav-item {
    margin-bottom: 15px;
  }
  .parentDiv .shopping__cart__table {
    overflow-y: auto;
  }
  .parentDiv
    .shopping__cart__table
    table
    tbody
    tr
    td.product__cart__item
    .product__cart__item__pic {
    float: none;
    margin-right: 0;
  }
  .parentDiv .continue__btn {
    text-align: center;
  }
  .parentDiv .continue__btn.update__btn {
    text-align: center;
    margin-top: 20px;
  }
  .parentDiv .cart__discount {
    margin-top: 40px;
  }
  .parentDiv .checkout__order {
    margin-top: 20px;
  }
  .parentDiv .blog__details__share {
    position: relative;
    left: 0;
    margin-bottom: 18px;
  }
  .parentDiv .blog__details__share span {
    margin-bottom: 14px;
    margin-right: 0;
  }
  .parentDiv .blog__details__share ul li {
    margin-bottom: 15px;
    display: inline-block;
    margin-right: 10px;
  }
  .parentDiv .blog__details__share ul li:last-child {
    margin-right: 0;
  }
  .parentDiv .blog__details__author {
    text-align: center;
    margin-bottom: 20px;
  }
  .parentDiv .blog__details__tags {
    text-align: center;
  }
  .parentDiv .contact__text {
    margin-bottom: 40px;
  }
  .parentDiv .hero__social {
    margin-top: 180px;
  }
}

/* Small Device = 320px */

@media only screen and (max-width: 479px) {
  .parentDiv .cart__total {
    padding: 35px 30px 40px;
  }
  .parentDiv .hero__items {
    height: auto;
    padding-top: 130px;
    padding-bottom: 40px;
  }
  .parentDiv .hero__text h2 {
    font-size: 36px;
    line-height: 48px;
  }
  .parentDiv .hero__social {
    margin-top: 145px;
  }
  .parentDiv .categories__deal__countdown .categories__deal__countdown__timer {
    margin-left: 0;
  }
  .parentDiv .instagram__pic__item {
    width: 100%;
  }
  .parentDiv .testimonial__text {
    padding: 60px 40px 60px;
  }
  .parentDiv
    .product__details__pic
    .nav-tabs
    .nav-item
    .nav-link
    .product__thumb__pic {
    width: 100%;
  }
  .parentDiv .product__details__pic .nav-tabs .nav-item {
    margin-bottom: 10px;
    width: calc(33.33% - 10px);
  }
  .parentDiv .product__details__last__option h5:before {
    width: 280px;
  }
  .parentDiv .product__details__cart__option .quantity {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .parentDiv .product__details__last__option h5 span {
    font-size: 16px;
  }
  .parentDiv .blog__hero__text h2 {
    font-size: 36px;
  }
  .parentDiv .categories__text h2 {
    font-size: 30px;
    line-height: 55px;
  }
  .parentDiv .categories__text:before {
    height: 250px;
  }
}
